<template>
  <main class="results page" v-if="dataLoaded">
    <section  v-if="results.length != 0" class="results-content mainContent">
      <sectionHeader/>
      <h1>Résultats</h1>
      <resultsJobsList @updateIndex="updateIndex" :initActiveJob="index"/>
      <resetBtn/>
    </section >

    <section  class="no-results-content mainContent" v-else>
      <sectionHeader/>
      <h1>Résultats</h1>
        <article class="job-description">
          Aucun résultats ne semble correspondre...
        </article>
      <resetBtn/>
    </section>

    <section class="job-section" v-if="results.length != 0">
      <article class="job-description">
        <div class="scroll-text-wrapper" data-simplebar >
         <section class="job-section-media">
            <!-- <video v-if="results.length != 0 && jobs[index].video" class="job-video" ref="videoRef" :key="index" :poster="jobs[index].img" controls>
              <source :src="jobs[index].video" type="video/mp4">
            </video> -->
            <video v-if="results.length != 0 && jobs[index].video" class="job-video" ref="videoRef" :key="index" controls>
              <source :src="jobs[index].video" type="video/mp4">
            </video>

            <div v-else class="mobile-img job-image">
              <img v-if="results.length != 0 && jobs[index].img " :src="jobs[index].img" :key="index"/>
              <img v-else :src="details.intro_image"/>
            </div>
          </section>

          <header class="job-section-header">
            <div class="job-header">
              <h2 class="job-title">{{jobs[index].metier}}</h2>
            </div>

            <nav class="jobs-description-nav">
              <div class="btn btn-description" :class="{active:!showCursus }" v-on:click="fnShowDescription" >Description <vue-feather type="arrow-right" size="30"></vue-feather></div>
              <div class="btn btn-cursus" :class="{active:showCursus}"  v-on:click="fnShowCursus">Cursus <vue-feather type="arrow-right" size="30"></vue-feather></div>
              <a v-if="jobs[index].lien" :href="jobs[index].lien" target="_blank" class="orientation-link">> En savoir plus</a> <!-- orientation link-->
            </nav>
          </header>

          <div v-if="showCursus===false" class="content description-content" v-html="jobs[index].description"></div>
          <div v-else class="content cursus-content" v-html="jobs[index].cursus"></div>
        </div>
      </article>

    </section>
  </main>
</template>

<script>
import sectionHeader from '@/components/sectionHeader.vue'
import resultsJobsList from '@/components/results/resultsJobsList.vue'
import resetBtn from '@/components/reset.vue'
import axios from 'axios'
import { mixins } from '../mixins/mixins.js'

export default {
  name: 'Results',
  mixins: [mixins],
  components: {
    sectionHeader,
    resultsJobsList,
    resetBtn
  },
  data () {
    return {
      index: 0,
      contentTitle: 'Description du métier',
      btnLabel: 'Cursus',
      showCursus: false,
      parameters: [],
      dataLoaded: false,
      usersResults: null
    }
  },

  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    quizResults () {
      return this.$store.state.userResponses
    },
    details () {
      return this.$store.state.details
    },
    results () {
      return this.$store.state.results
    }
  },

  methods: {
    updateIndex (variable) {
      this.index = variable
    },
    toggleCursus () {
      if (this.showCursus === false) {
        this.showCursus = true
        this.contentTitle = 'Cursus'
        this.btnLabel = 'Description'
      } else {
        this.contentTitle = 'Description du métier'
        this.showCursus = false
        this.btnLabel = 'Cursus'
      }
      console.log(this.showCursus)
    },
    fnShowCursus () {
      this.showCursus = true
      this.contentTitle = 'Cursus'
    },
    fnShowDescription () {
      this.showCursus = false
      this.contentTitle = 'Description du métier'
    }
  },

  mounted () {
    // récupérer le paramaètre ID
    const urlParams = new URLSearchParams(window.location.search)
    this.parameters = ''
    this.parameters = urlParams.get('entry_id')
    if (!this.parameters) {
      this.dataLoaded = true
    } else {
      axios.get(
        this.$store.state.sourceUrl + '/wp-json/wp/v2/entree/' + this.parameters,
        {
          // withCredentials: true,
          auth: {
            username: 'jerome',
            password: 'gG1t 6T8m tmL9 UzBS fAEO gpgx'
          }
        })
        .then(response => {
          this.usersResults = response.data
          console.log('resultats obtenus')
          console.log(this.usersResults)
          this.$store.state.results = this.usersResults.acf.resultat
          this.dataLoaded = true
          // Mettre à jour l'index après le chargement des résultats
          if (this.results.length > 0) {
            this.index = this.results[0].jobIndex
          }
        })
        .catch(error => {
          console.log('Bad news with the datas')
          console.log(error)
        })
    }
  },

  watch: {
    index () {
      const video = this.$refs.videoRef
      if (video && !video.paused) {
        video.pause()
        video.currentTime = 0
      }
    },
    dataLoaded (newVal) {
      if (newVal && this.results.length > 0) {
        this.index = this.results[0].jobIndex
      }
    }
  }
}
</script>

<style lang="scss">

  .results {
    .results-content {
      padding: 40px;
      @media all and (min-width: 769px) {
        padding-right: 100px;
      }
      @media all and (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .results-content .section-header {
      margin-bottom: 70px;
    }

    .no-results-content .section-header {
      margin-bottom: 32vh;
    }
  }

  .results-content  {
     @media all and (max-width: 768px) {
      h1 {
        font-size: 50px;
      }

      @media all and (max-width: 540px) {
        h1 {
          text-align: left;
          width: 100%;
        }
      }
    }
    .reset-btn{
      margin-top: 0;
      font-size: 15px;
    }
  }

  .mobile-img {
    margin-top: 0;
    margin-bottom: 30px;

    &.job-image {
      height: 300px;
      @media all and (min-width: 769px) {
        height: 400px;
        display: block;
      }
    }
  }

  .scroll-text-wrapper {
      height: auto;
      @media all and (min-width: 769px) {
        height: calc(100vh - 80px);
        padding-right: 40px;
      } 
    }

  .job-section {
    padding: 40px;
  }
  .job-description {
    width: 100%;
    max-width: 1200px;
    font-size: 20px;

    .job-video {
      width: 100%;
      height: auto;
      margin-top: 0;
      margin-bottom: 30px;
      object-fit: cover;
    }

    .job-section-header {
      margin-bottom: 30px;
      align-items: center;

      .job-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .job-title {
          width: 70%;
          margin-right: auto;
          text-align: left;
          margin: 0 0 auto 0;
          // min-height: 80px;

          @media all and (max-width: 768px) {
            font-size: 28px;
          }

          @media all and (max-width: 540px) {
            font-size: 20px;
          }
        }
      }

      .jobs-description-nav {
        display: flex;
        // justify-content: space-between;

        .btn-description,
        .btn-cursus {
          vertical-align: middle;
          font-size: 18px;
          border-width: 1px;
          transition: all 0.3s;

          @media all and (max-width: 768px) {
            font-size: 16px;
          }

          &:first-of-type {
            margin-right: 10px;
          }

          &.active {
            background-color: #000;
            color: #fff;
          }

          .vue-feather--arrow-right {
            display: inline-block;
            width: 0;
            overflow: hidden;
            //font-size: 30px;
            vertical-align: middle;
            transition: margin 0.3s;
          }

          &.active,
          &:hover {
            .vue-feather--arrow-right {
              width: 20px;
              margin-left: 8px;
            }
          }
        }

        .orientation-link {
          margin-left: auto;
          font-size: 16px;
          color: #000;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .content {
      text-align: left;
      font-size: 16px;
      a {
        color: #000;
      }
      @media all and (max-width: 768px) {
        font-size: 16px;
        padding-right: 0;

        ul, li {
          padding-right: 0;

        }
      }
      @media all and (max-width: 540px) {
        font-size: 16px;
      }

      table {
        width: 100%;
        border-spacing: 0;

        @media all and (max-width: 768px) {
          font-size: 18px !important;
        }
        @media all and (max-width: 540px) {
          font-size: 16px !important;
        }
      }

      ul {
        padding-left: 18px;
      }
    }
  }
</style>
