<template>
   <!-- à part des data dans jobs, créer un tableau catégories. -->
     <!-- générer une liste des catégories -> pour chaque catégorie créer un dropdown a partir du tableau results et afficher uniquement les métiers qui on la catégorie correspondante  -->
  <div class="jobsList">
      <div v-for="(category, index) in sortedCategories" :key="index" class="cat-results" >
        
        <h4 @click="toggleDropdown(index)">{{  decodeHtml(category) }} <span>({{ filteredResultsByCategory(category).length }})</span></h4>

        <ul v-if="isDropdownOpen(index)" class="dropdown-menu" data-simplebar>
          <li v-for="result in filteredResultsByCategory(category)" :key="result.jobIndex"
          @click="updateIndex(result.jobIndex)"
          :class="['job', (activeLink===result.jobIndex ? 'active' : '') ]"
          :style="{color:percentageColor(result.percentage)}"
          >
            <!-- <div class="count">{{ index+1 }}. </div> -->
            <span>{{ jobs[result.jobIndex].metier }}</span>
            <!-- <span class="percentage">{{ result.percentage }}%</span> -->
          </li>
        </ul>

      </div>
  </div>
</template>

<style lang="scss">

  .jobsList {
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100%;
    margin-left: auto;
    //height: calc(75vh - 80px);
      // padding-right: 40px;
    // overflow-y: scroll;

    @media all and (max-width: 768px) {
      text-align: left;
    }

    .cat-results {
      margin-bottom:20px;
      h4 {
        cursor: pointer;
        //§font-weight: 100;
        span {
          font-family: 'Titillium Web', sans-serif;
        }
      }
      ol,
      ul {
        height: auto;
        max-height: 210px;
        list-style: none;
        margin: 0;
        padding:0 0 0px 0;
        padding-right: 20px;
        margin-top: 15px;

        li.job {
          cursor: pointer;
          font-size: 16px;
          margin-left: 0px;
          line-height: 1.2;
          margin-bottom: 8px;
          position: relative;

          .percentage {
            &:before {
              content: ' - ';
            }
          }

          @media all and (max-width: 540px) {
            font-size: 15px;
            display: flex;

            .percentage {
              margin-left: auto;
              &:before {
                content: '';
              }
            }
          }

          .count{
            position:relative;
            display: inline-block;
            padding-right: 5px;
          }
        }

        .clicked {
          text-decoration: underline;
          @media all and (max-width: 540px) {
            text-decoration: inherit;
          }
        }
      }
    }
  }
  .active {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media all and (max-width: 540px) {
      display: inherit;
    }

    @media all and (max-width: 540px) {
      align-items: left;
      justify-content: flex-start;
    }

    &::before {
      content: '';
      display: block;
      margin-left: -8px;
      margin-right: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid #000;

      @media all and (max-width: 540px) {
        display: none;
      }
    }
  }

</style>

<script>
export default {
  name: 'resultsJobsList',

  props: {
    initActiveJob: Number,
    // results: Array
  },

  data () {
    return {
      // results: [],
      activeLink: this.initActiveJob,
      color: '#000',
      prevIndex: 0,
      openDropdownIndex: null
    }
  },
  methods: {
    updateIndex: function (value) {
      this.$emit('updateIndex', value)
      this.activeLink = value
    },
    onclick (value) {
      this.updateIndex(value)
      this.prevIndex = value
    },
    mouseover (index) {
      this.updateIndex(index)
    },
    mouseleave () {
      this.updateIndex(this.prevIndex)
    },
    decodeHtml(html) {
      const txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    percentageColor ($percentage) {
      let color = 'black'
      if ($percentage >= 90) {
        color = 'green'
      } else if ($percentage >= 80 && $percentage < 90) {
        color = 'YellowGreen'
      } else if ($percentage >= 70 && $percentage < 80) {
        color = 'orange'
      } else if ($percentage >= 0 && $percentage < 70) {
        color = 'Tomato'
      }
      return color
    },
    filteredResultsByCategory(category) {
      return this.results.filter(result => result.jobCategory === category)
    },
    sortCategories(categories) {
      return categories.sort((a, b) => {
        if (a === 'Autres métiers') return 1
        if (b === 'Autres métiers') return -1
        return a.localeCompare(b)
      })
    },
    toggleDropdown(index) {
      console.log('toggleDropdown', index)
      if (this.openDropdownIndex === index) {
        this.openDropdownIndex = null
      } else {
        this.openDropdownIndex = index
      }
    },
    isDropdownOpen(index) {
      return this.openDropdownIndex === index
    },
    findActiveDropdown() {
      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i]
        const results = this.filteredResultsByCategory(category)
        if (results.some(result => result.jobIndex === this.activeLink)) {
          this.openDropdownIndex = i
          break
        }
      }
    }
  },
  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    userResponses () {
      return this.$store.state.userResponses
    },
    results () {
      return this.$store.state.results
    },
    categories () {
     console.log(this.jobs)
      const categoriesSet = new Set()
      this.jobs.forEach(job => {
        if (job.category_name
        ) {
          categoriesSet.add(job.category_name
          )
        }
      })
      return Array.from(categoriesSet)
    },
    sortedCategories() {
      return this.sortCategories(this.categories)
    }
  },
  mounted: function () {
    this.findActiveDropdown()
  }
}
</script>
